/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react"
import { TokenIcon } from "../../../components/partials/TokenIcon"
import { Link } from "react-router-dom"
import { GetKrc20ActiveMarketResponse } from "../../../../client"
import { useIntl } from "react-intl"
import { Skeleton } from "antd"

type Props = {
  className?: string
  markets?: GetKrc20ActiveMarketResponse[]
  loading?: boolean
}

const MARGIN_BOTTOM_SIZE = "8"

const Row = ({
  market,
  rank,
  rightText,
}: {
  market: GetKrc20ActiveMarketResponse
  rank?: number
  rightText?: React.ReactNode
}) => {
  return (
    <div className={`d-flex align-items-center mb-${MARGIN_BOTTOM_SIZE}`}>
      {rank && (
        <span className="fs-5 fw-semibold text-gray-600 me-3">{rank}</span>
      )}
      <TokenIcon url={market?.metadata?.iconUrl} className="me-3" size="30px" />
      <div className="flex-grow-1">
        <Link
          to={market?.metadata?.url}
          className="text-body text-hover-primary fw-bold fs-6"
          target="_blank"
        >
          {market?.metadata?.name}
        </Link>
      </div>
      {rightText}
    </div>
  )
}

export const TopMarketsCard: React.FC<Props> = ({
  className = "",
  markets,
  loading,
}) => {
  const intl = useIntl()
  const rows = useMemo(() => {
    return markets?.map((market, index) => (
      <Row
        market={market}
        rank={index + 1}
        rightText={
          <span className="">
            $
            {market?.volume24hInUsd.toLocaleString(undefined, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
          </span>
        }
      />
    ))
  }, [markets])

  return (
    <div className={`card ${className} h-350px`}>
      <div className="card-header border-0">
        <h3 className="card-title fw-bold text-dark">
          {intl.formatMessage({
            id: "TOP_MARKETS_CARD.TITLE",
          })}
        </h3>
        <div className="card-toolbar">
          <span className="text-muted">
            {intl.formatMessage({
              id: "TOP_MARKETS_CARD.DESCRIPTION",
            })}
          </span>
        </div>
      </div>
      <div className={`card-body pt-2 mb-n${MARGIN_BOTTOM_SIZE}`}>
        {loading ? <Skeleton active /> : rows}
      </div>
    </div>
  )
}
