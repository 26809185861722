import { useRequest, useTitle } from "ahooks"
import { rowClass, TITLE_SUFFIX } from "../../constants/constants"
import { HoldersListSection } from "./HoldersListSection"
import { HoldersMetricsSection } from "./HoldersMetricsSection"
import { DistributionTrendSection } from "./DistributionTrendSection"
import { AnalyticsService } from "../../../client"
import { DistributionTableSection } from "./DistributionTableSection"
import { HelmetWrapper } from "../../utils/HelmetWrappper"
import { AdsPlacement } from "../../components/Ads/AdsPlacement"

const sectionClass = "col-xl-12 mb-5 mb-xl-8"

const HoldersWrapper = () => {
  useTitle(`Top addresses ${TITLE_SUFFIX}`, {
    restoreOnUnmount: true,
  })

  const { data: distributionTrendData, loading } = useRequest(
    AnalyticsService.analyticsControllerGetActiveAddressesTrend
  )

  return (
    <>
      <HelmetWrapper
        title={`Top Kaspa addresses ${TITLE_SUFFIX}`}
        description={
          "Top 10,000 richest Kaspa addresses, including the active address count, and the distribution chart of addresses holding KAS over time."
        }
      />

      <div className={rowClass}>
        <HoldersMetricsSection />
      </div>

      <div className={rowClass}>
        <div className={sectionClass}>
          <DistributionTableSection
            distributionData={
              distributionTrendData
                ? distributionTrendData[distributionTrendData?.length - 1]
                : undefined
            }
            loading={loading}
          />
        </div>
      </div>

      <div className="mb-3 mt-n5 mt-xl-n3 d-flex justify-content-center">
        <AdsPlacement placementType="banner" platform="coinzilla" />
      </div>

      <div className={rowClass}>
        <div className={sectionClass}>
          <DistributionTrendSection
            data={distributionTrendData}
            loading={loading}
          />
        </div>
      </div>

      <div className={rowClass}>
        <div className={sectionClass}>
          <HoldersListSection />
        </div>
      </div>
    </>
  )
}

export { HoldersWrapper }
