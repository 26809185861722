import { Tooltip } from "antd"

import { WarningOutlined } from "@ant-design/icons"
import { useIntl } from "react-intl"

export const MIN_SIGNIFICANT_TRADE_VOLUME_IN_USD = 100

export const LowLiquidityWarningIcon = () => {
  const intl = useIntl()
  return (
    <Tooltip
      title={intl.formatMessage({
        id: "LOW_LIQUIDITY_MARTKET_CAP_WARNING",
      })}
    >
      <WarningOutlined className="ms-2 text-warning" />
    </Tooltip>
  )
}
