export const coinzillaHtmlTemplateBanner = `
    <script async src="https://cdn.adx.ws/scripts/loader.js"></script>
    <div class="sevioads" data-zone="f14a07cc-1f25-474e-af5c-a4237b4ca405"></div>
    <script>
        window.sevioads = window.sevioads || [];
        var sevioads_preferences = [];
        sevioads_preferences[0] = {};
        sevioads_preferences[0].zone = "f14a07cc-1f25-474e-af5c-a4237b4ca405";
        sevioads_preferences[0].adType = "banner";
        sevioads_preferences[0].inventoryId = "ef226577-c10e-4fe3-883d-0bd733cb1242";
        sevioads_preferences[0].accountId = "ee4c01b8-2bbe-4b6d-8b2f-2e78f48fb639";
        sevioads.push(sevioads_preferences);
    </script>
  `

export const coinzillaHtmlTemplateMobileBanner = `
    <script async src="https://cdn.adx.ws/scripts/loader.js"></script>
    <div class="sevioads" data-zone="b601d0df-a573-4ac7-85b1-f22662c570ef"></div>
    <script>
        window.sevioads = window.sevioads || [];
        var sevioads_preferences = [];
        sevioads_preferences[0] = {};
        sevioads_preferences[0].zone = "b601d0df-a573-4ac7-85b1-f22662c570ef";
        sevioads_preferences[0].adType = "banner";
        sevioads_preferences[0].inventoryId = "ef226577-c10e-4fe3-883d-0bd733cb1242";
        sevioads_preferences[0].accountId = "ee4c01b8-2bbe-4b6d-8b2f-2e78f48fb639";
        sevioads.push(sevioads_preferences);
    </script>
  `

export const coinzillaHtmlTemplateBox = `
    <script async src="https://cdn.adx.ws/scripts/loader.js"></script>
    <div class="sevioads" data-zone="154a95e6-0e34-4b9e-9dc2-eb08d42ab39d"></div>
    <script>
        window.sevioads = window.sevioads || [];
        var sevioads_preferences = [];
        sevioads_preferences[0] = {};
        sevioads_preferences[0].zone = "154a95e6-0e34-4b9e-9dc2-eb08d42ab39d";
        sevioads_preferences[0].adType = "banner";
        sevioads_preferences[0].inventoryId = "ef226577-c10e-4fe3-883d-0bd733cb1242";
        sevioads_preferences[0].accountId = "ee4c01b8-2bbe-4b6d-8b2f-2e78f48fb639";
        sevioads.push(sevioads_preferences);
    </script>
  `
