/* eslint-disable react/jsx-no-target-blank */

import { useIntl } from "react-intl"
import { CHAINGE_CHANNEL_ID } from "../../constants/constants"
import { trackTokenAdsClickedEvent } from "../../utils/tokenUtils"

export const TradeOnChaingeButton = ({ ticker }: { ticker?: string }) => {
  const intl = useIntl()
  const url = `https://knot.meme/token/krc20/${ticker}?channel=${CHAINGE_CHANNEL_ID}`

  return null
  return (
    <a
      className="btn btn-info btn-sm"
      href={url}
      target="_blank"
      onClick={() => trackTokenAdsClickedEvent("chainge_trade_button")}
    >
      <i className="bi bi-currency-exchange fs-4 me-2"></i>
      <span className="fs-6 fw-semibold">
        {intl.formatMessage(
          { id: "TRADE_ON_CHAINGE_FOR_TICKER" },
          { ticker: ticker || intl.formatMessage({ id: "KRC20_TOKENS" }) }
        )}
      </span>
    </a>
  )
}
