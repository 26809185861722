import { useCountDown } from "ahooks"
import { useGlobalData } from "../utils/GlobalDataProvider"
import { CRESCENDO_ACTIVATION_DAA } from "../constants/constants"

export const CrescendoCountdownLabel = () => {
  const { networkData } = useGlobalData()
  const [, formattedRes] = useCountDown({
    leftTime:
      (CRESCENDO_ACTIVATION_DAA - Number(networkData?.virtualDaaScore || 0)) *
      1e3,
  })

  const { seconds, minutes, hours, days } = formattedRes

  if (!networkData) {
    return null
  }

  if (networkData.virtualDaaScore >= CRESCENDO_ACTIVATION_DAA) {
    return <span>0 secs</span>
  }

  if (days === 0 && hours === 0) {
    return (
      <span>
        {minutes} min {seconds} sec
      </span>
    )
  }
  if (days === 0) {
    return (
      <span>
        {hours} hr {minutes} min
      </span>
    )
  }
  return (
    <span>
      {days} day {hours} hr
    </span>
  )
}
