/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from "react-intl"
import Table, { ColumnsType } from "antd/es/table"
import {
  DistributionTrendCategory,
  DistributionTrendResponse,
} from "../../../client"
import { useMemo } from "react"
import {
  formatCash,
  formatIsoTime,
  formatKaspaAmount,
  kaspaAmountToInt,
} from "../../utils/utils"
import { Card } from "../../components/Card"
import { useGlobalData } from "../../utils/GlobalDataProvider"
import { FiatAmountBadge } from "../../components/FiatAmountBadge"
import { DiffValueBadge } from "../../components/DiffValueBadge"

interface DataType {
  key: React.Key
  category: string
  balance: string
  totalBalance: number
  supplyPct: string
  count: number
  change24Hour?: number
  change7Day?: number
  change30Day?: number
  change90Day?: number
}

const percentageFormatter = (val: number) => {
  let badgeColor, sign
  const diff = parseFloat(val?.toFixed(1) || "0")
  // Since we use .toFixed(1), the min step is 0.1
  if (diff >= 0.1) {
    badgeColor = "badge-light-success"
    sign = "+"
  } else if (diff <= -0.1) {
    badgeColor = "badge-light-danger"
    sign = "-"
  } else {
    badgeColor = "badge-light"
    sign = ""
  }
  const number = Math.abs(diff)
  const text = `${sign}${number.toFixed(1)}%`
  return <span className={`badge ${badgeColor} fs-base`}>{text}</span>
}

const DistributionTableSection = ({
  distributionData,
  loading,
}: {
  distributionData?: DistributionTrendResponse
  loading?: boolean
}) => {
  const intl = useIntl()
  const { showAddressDistAbsoluteDiff, toggleShowAddressDistAbsoluteDiff } =
    useGlobalData()

  const columns: ColumnsType<DataType> = [
    {
      title: intl.formatMessage({ id: "CATEGORY" }),
      dataIndex: "category",
      render: (val: string) => {
        return <span className="text-dark">{val}</span>
      },
      fixed: "left",
    },
    {
      title: intl.formatMessage({ id: "BALANCE_IN_KAS" }),
      dataIndex: "balance",
    },
    {
      title: intl.formatMessage({ id: "COUNT" }),
      dataIndex: "count",
      render: (val: number) => {
        return <span>{val.toLocaleString()}</span>
      },
    },
    {
      title: showAddressDistAbsoluteDiff ? "1h" : "1h %",
      dataIndex: "change1Hour",
      render: (val: number) => {
        return (
          <span>
            {showAddressDistAbsoluteDiff ? (
              <DiffValueBadge diff={val} />
            ) : (
              percentageFormatter(val)
            )}
          </span>
        )
      },
    },
    {
      title: showAddressDistAbsoluteDiff ? "24h" : "24h %",
      dataIndex: "change24Hour",
      render: (val: number) => {
        return (
          <span>
            {showAddressDistAbsoluteDiff ? (
              <DiffValueBadge diff={val} />
            ) : (
              percentageFormatter(val)
            )}
          </span>
        )
      },
    },
    {
      title: showAddressDistAbsoluteDiff ? "7d" : "7d %",
      dataIndex: "change7Day",
      render: (val: number) => {
        return (
          <span>
            {showAddressDistAbsoluteDiff ? (
              <DiffValueBadge diff={val} />
            ) : (
              percentageFormatter(val)
            )}
          </span>
        )
      },
    },
    {
      title: showAddressDistAbsoluteDiff ? "30d" : "30d %",
      dataIndex: "change30Day",
      render: (val: number) => {
        return (
          <span>
            {showAddressDistAbsoluteDiff ? (
              <DiffValueBadge diff={val} />
            ) : (
              percentageFormatter(val)
            )}
          </span>
        )
      },
    },
    {
      title: showAddressDistAbsoluteDiff ? "90d" : "90d %",
      dataIndex: "change90Day",
      render: (val: number) => {
        return (
          <span>
            {showAddressDistAbsoluteDiff ? (
              <DiffValueBadge diff={val} />
            ) : (
              percentageFormatter(val)
            )}
          </span>
        )
      },
    },
    {
      title: intl.formatMessage({ id: "TOTAL_BALANCE" }),
      dataIndex: "totalBalance",
      render: (balance: number) => {
        return (
          <div className="d-flex align-items-center">
            {formatKaspaAmount(balance, 0)}
            <FiatAmountBadge
              kaspaAmount={kaspaAmountToInt(balance)}
              precision={0}
            />
          </div>
        )
      },
    },
    {
      title: intl.formatMessage({ id: "PCT_OF_SUPPLY" }),
      dataIndex: "supplyPct",
    },
  ]

  const data: DataType[] = useMemo(() => {
    if (!distributionData) return []

    const categories = []
    for (let i: number = 3; i < 11; i++) {
      const key = `addressesIn1e${i}`
      const lowerBound = i > 2 ? `[${formatCash(1 * 10 ** (i - 1))}` : "(0"
      const upperBound = `${formatCash(1 * 10 ** i)})`
      const categoryData = distributionData[
        key as keyof DistributionTrendResponse
      ] as DistributionTrendCategory
      categories.push({
        key: i,
        category: intl.formatMessage({
          id: `HOLDERS.ADDRESSES_DISTRIBUTION.CATEGORY.${i}`,
        }),
        balance: `${lowerBound} - ${upperBound}`,
        totalBalance: categoryData.totalBalance,
        supplyPct: (categoryData.supplyPct * 100).toFixed(2) + "%",
        count: categoryData.count,
        change1Hour: showAddressDistAbsoluteDiff
          ? categoryData.changeDiff1h
          : categoryData.changePct1h,
        change24Hour: showAddressDistAbsoluteDiff
          ? categoryData.changeDiff24h
          : categoryData.changePct24h,
        change7Day: showAddressDistAbsoluteDiff
          ? categoryData.changeDiff7d
          : categoryData.changePct7d,
        change30Day: showAddressDistAbsoluteDiff
          ? categoryData.changeDiff30d
          : categoryData.changePct30d,
        change90Day: showAddressDistAbsoluteDiff
          ? categoryData.changeDiff90d
          : categoryData.changePct90d,
      })
    }
    return categories
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributionData, showAddressDistAbsoluteDiff])

  const AbsoluteDiffToggle = () => {
    return (
      <div className="form-check form-check-solid form-switch fv-row d-flex">
        <input
          className="form-check-input w-30px h-20px me-2"
          type="checkbox"
          onChange={toggleShowAddressDistAbsoluteDiff}
          checked={showAddressDistAbsoluteDiff}
        />
        <label className="form-check-label">
          {intl.formatMessage({
            id: "HOLDERS.ADDRESSES_DISTRIBUTION.ABSOLUTE_DIFF_TOGGLE.TITLE",
          })}
        </label>
      </div>
    )
  }

  return (
    <Card
      className="pb-8"
      title={intl.formatMessage({
        id: "HOLDERS.ADDRESSES_DISTRIBUTION.TITLE",
      })}
      description={intl.formatMessage(
        {
          id: "HOLDERS.ADDRESSES_DISTRIBUTION.DESCRIPTION",
        },
        {
          time:
            distributionData?.createdAt &&
            formatIsoTime(distributionData?.createdAt),
        }
      )}
      toolbar={<AbsoluteDiffToggle />}
      body={
        <div>
          <Table
            columns={columns}
            dataSource={data}
            scroll={{ x: "max-content" }}
            pagination={false}
            loading={loading}
            size="small"
            footer={() => {
              return (
                <a
                  role="button"
                  className="fw-bold text-gray-800 text-hover-primary fs-6 text-break d-flex justify-content-center"
                  href="#top-addresses"
                >
                  {intl.formatMessage({
                    id: "HOLDERS.TOP_HOLDERS.TITLE",
                  }) + " >"}
                </a>
              )
            }}
          />
        </div>
      }
    />
  )
}

export { DistributionTableSection }
