/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import { Table, Tooltip } from "antd"
import Search from "antd/es/input/Search"
import { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { GetTokenSummaryResponse } from "../../../client"
import { Card } from "../../components/Card"
import { getTokenRoute, getTransactionRoute } from "../../hooks/navigator"
import { ColumnsType } from "antd/es/table"
import { formatTokenAmount } from "../../utils/tokenUtils"
import {
  formatCash,
  formatUnixTime,
  getTimeDifference,
} from "../../utils/utils"
import { TokenIcon } from "../../components/partials/TokenIcon"
import { PriceDiffBadge } from "../../components/PriceDiffBadge"
import { PremintPctBadge } from "../../components/partials/PremintPctBadge"
import { TokenMintedProgressiveBar } from "../../components/partials/TokenMintedProgressiveBar"
import { MiniPriceChart } from "../../components/charts/MiniPriceChart"
import { TransactionViewType } from "../address/components/AddressTransactions"
import { TokenTradingVolume } from "../../components/partials/TokenTradingVolume"
import { Link } from "react-router-dom"
import { useGlobalData } from "../../utils/GlobalDataProvider"
import { Krc20PriceUnitToggleButton } from "../../components/partials/Krc20PriceUnitToggleButton"
import { useLocalStorageState } from "ahooks"
import {
  LowLiquidityWarningIcon,
  MIN_SIGNIFICANT_TRADE_VOLUME_IN_USD,
} from "../../components/partials/LowLiquidityWarningIcon"
import { BookmarkTokenIcon } from "../../components/partials/BookmarkTokenIcon"
import { BookmarkTokenEnableButton } from "../../components/partials/BookmarkTokenEnableIcon"
import {
  bookmarkedTokensKey,
  watchlistEnabledKey,
} from "../../constants/localStorageKeys"
import { getTokenDisplayPrice } from "../token/utils"
import useUrlState from "@ahooksjs/use-url-state"

export enum TokenListViewType {
  TokenList = "token_list",
  Trending = "trending",
}

const Krc20TokenList = ({ tokens }: { tokens?: GetTokenSummaryResponse[] }) => {
  const intl = useIntl()
  const [urlState, setUrlState] = useUrlState({
    page: 1,
  })

  const [bookmarkedTokens] = useLocalStorageState<string[]>(
    bookmarkedTokensKey,
    {
      defaultValue: [],
      listenStorageChange: true,
    }
  )
  const [watchlistEnabled] = useLocalStorageState<boolean>(
    watchlistEnabledKey,
    {
      defaultValue: false,
      listenStorageChange: true,
    }
  )

  const [tableData, setTableData] = useState<GetTokenSummaryResponse[]>()
  const { krc20PriceUnit } = useGlobalData()

  const columns = useMemo((): ColumnsType<GetTokenSummaryResponse> => {
    return [
      {
        title: "#",
        align: "center",
        render: (_, record) => {
          return (
            <div className="d-flex align-items-center gap-4">
              <BookmarkTokenIcon ticker={record.ticker} />
              {record.rank}
            </div>
          )
        },
        sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
          a.rank - b.rank,
      },
      {
        title: intl.formatMessage({ id: "TICKER" }),
        fixed: "left",
        render: (_, record: GetTokenSummaryResponse) => {
          const { ticker } = record

          return (
            <div className="d-flex align-items-center">
              <Link
                className="fw-bold d-flex align-items-center text-body text-hover-primary"
                to={getTokenRoute(ticker)}
              >
                <TokenIcon url={record.iconUrl} className="me-2" size="25px" />
                {ticker}
              </Link>
              {/* <Tooltip
                title={intl.formatMessage({ id: "TRADEABLE_ON_KASFYI" })}
                placement="right"
              >
                {record.tradeEnabled ? (
                  <i className="bi bi-check-circle text-success ms-2"></i>
                ) : null}
              </Tooltip> */}
            </div>
          )
        },
        sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
          a.ticker.localeCompare(b.ticker),
      },
      {
        title: intl.formatMessage({
          id: krc20PriceUnit === "USD" ? "PRICE_IN_USD" : "PRICE_IN_KAS",
        }),
        render: (_, record: GetTokenSummaryResponse) => {
          const { price } = record
          const priceChange =
            krc20PriceUnit === "KAS" ? price?.change24hInKas : price?.change24h

          return (
            <Link
              to={getTokenRoute(
                record.ticker,
                TransactionViewType.TokenPriceChart
              )}
              className="d-flex align-items-center text-body text-hover-primary"
            >
              <span className="me-2">
                {getTokenDisplayPrice({ price, priceUnit: krc20PriceUnit })}
              </span>

              {priceChange ? (
                <PriceDiffBadge diff={priceChange} precision={0} />
              ) : null}
            </Link>
          )
        },
        sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
          (a.price?.floorPrice || 0) - (b.price?.floorPrice || 0),
        filters: [
          {
            text: intl.formatMessage({ id: "HAS_PRICE" }),
            value: true,
          },
          {
            text: intl.formatMessage({ id: "NO_PRICE" }),
            value: false,
          },
        ],
        onFilter: (value, record) => {
          if (value === true) {
            return record.price !== undefined && record.price.floorPrice > 0
          }

          return record.price?.floorPrice === 0 || !record.price
        },
      },

      {
        title: intl.formatMessage({ id: "LAST_7_DAYS" }),
        width: 140,
        render: (_, record: GetTokenSummaryResponse) => {
          const { priceCandles, price } = record
          const change24h =
            krc20PriceUnit === "KAS" ? price?.change24hInKas : price?.change24h
          const chartColor = change24h && change24h >= 0 ? "#47BE7D" : "#F1416C"

          return (
            <Link
              to={getTokenRoute(
                record.ticker,
                TransactionViewType.TokenPriceChart
              )}
            >
              <MiniPriceChart
                data={
                  priceCandles
                    ?.sort(
                      (a, b) =>
                        new Date(a.timestamp).getTime() -
                        new Date(b.timestamp).getTime()
                    )
                    .map((p) =>
                      Number(krc20PriceUnit === "KAS" ? p.close_kas : p.close)
                    ) || []
                }
                color={chartColor}
              />
            </Link>
          )
        },
      },
      {
        title: intl.formatMessage({ id: "DASHBOARD.MARKET.MARKET_CAP.TITLE" }),
        render: (_, record: GetTokenSummaryResponse) => {
          const { price = { marketCapInUsd: 0 }, tradeVolume } = record
          const { marketCapInUsd } = price

          return (
            <Link
              to={getTokenRoute(
                record.ticker,
                TransactionViewType.TokenPriceChart
              )}
              className="text-body text-hover-primary"
            >
              {marketCapInUsd ? (
                <div>
                  {`$${marketCapInUsd.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })}`}
                  {!tradeVolume ||
                  tradeVolume.amountInUsd <
                    MIN_SIGNIFICANT_TRADE_VOLUME_IN_USD ? (
                    <LowLiquidityWarningIcon />
                  ) : null}
                </div>
              ) : (
                <div>-</div>
              )}
            </Link>
          )
        },
        sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) => {
          const aMarketCapInUsd = a.price?.marketCapInUsd || 0
          const bMarketCapInUsd = b.price?.marketCapInUsd || 0

          return aMarketCapInUsd - bMarketCapInUsd
        },
      },
      {
        title: intl.formatMessage({ id: "TRADING_VOLUME_24H" }),
        filters: [
          {
            text: intl.formatMessage({ id: "NON_ZERO_TRADING_VOLUME" }),
            value: true, // indicate the filter is applied
          },
        ],
        onFilter: (value, record) => {
          if (value) {
            const { tradeVolume } = record
            return tradeVolume?.amountInUsd > 0
          }

          return true
        },
        render: (_, record: GetTokenSummaryResponse) => {
          const { tradeVolume } = record

          return (
            <Link
              to={getTokenRoute(
                record.ticker,
                TransactionViewType.TokenPriceChart
              )}
              className="text-body text-hover-primary"
            >
              {tradeVolume?.amountInUsd ? (
                <TokenTradingVolume tradeVolume={tradeVolume} />
              ) : (
                "-"
              )}
            </Link>
          )
        },
        sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
          a?.tradeVolume?.amountInUsd - b?.tradeVolume?.amountInUsd,
      },
      {
        title: intl.formatMessage({ id: "AGE" }),
        render: (_, record: GetTokenSummaryResponse) => {
          const { deployedAt } = record
          return (
            <Tooltip title={formatUnixTime(deployedAt / 1000)}>
              <Link
                to={getTransactionRoute(record.revealHash)}
                className="text-body text-hover-primary"
              >
                {getTimeDifference(deployedAt, true)}
              </Link>
            </Tooltip>
          )
        },
        sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
          a.deployedAt - b.deployedAt,
      },

      {
        title: intl.formatMessage({ id: "TOKEN.HEADER.PRE_MINT" }),
        render: (_, record: GetTokenSummaryResponse) => {
          return <PremintPctBadge token={record} precision={0} />
        },
        sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) => {
          const aPreMintedPct = (a.preMint / a.maxSupply) * 100
          const bPreMintedPct = (b.preMint / b.maxSupply) * 100
          return aPreMintedPct - bPreMintedPct
        },
        filters: [
          {
            text: intl.formatMessage({ id: "FAIR_LAUNCH" }),
            value: 0,
          },
          {
            text: intl.formatMessage({ id: "LESS_THAN_10_PCT_PRE_MINT" }),
            value: 10,
          },
          {
            text: intl.formatMessage({ id: "LESS_THAN_50_PCT_PRE_MINT" }),
            value: 50,
          },
          {
            text: intl.formatMessage({ id: "LESS_THAN_100_PCT_PRE_MINT" }),
            value: 100,
          },
        ],
        onFilter: (value, record) => {
          const valueNumber = Number(value)
          const preMintedPct = (record.preMint / record.maxSupply) * 100

          if (valueNumber === 100) {
            return preMintedPct === valueNumber
          }

          return preMintedPct <= valueNumber
        },
      },
      {
        title: intl.formatMessage({ id: "MINTED" }),
        render: (_, record: GetTokenSummaryResponse) => {
          return <TokenMintedProgressiveBar token={record} />
        },
        sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) => {
          const aCurrentSupplyPct = (a.totalMinted / a.maxSupply) * 100
          const bCurrentSupplyPct = (b.totalMinted / b.maxSupply) * 100
          return aCurrentSupplyPct - bCurrentSupplyPct
        },
        filters: [
          {
            text: intl.formatMessage({ id: "ZERO_MINTED" }),
            value: 0,
          },
          {
            text: intl.formatMessage({ id: "LESS_THAN_10_PCT_MINTED" }),
            value: 10,
          },
          {
            text: intl.formatMessage({ id: "LESS_THAN_50_PCT_MINTED" }),
            value: 50,
          },
          {
            text: intl.formatMessage({ id: "LESS_THAN_100_PCT_MINTED" }),
            value: -1,
          },
          {
            text: intl.formatMessage({ id: "FULLY_MINTED" }),
            value: 100,
          },
        ],
        onFilter: (value, record) => {
          const valueNumber = Number(value)
          const currentSupplyPct = (record.totalMinted / record.maxSupply) * 100

          if (valueNumber === 100) {
            return currentSupplyPct === valueNumber
          }

          if (valueNumber === -1) {
            return currentSupplyPct < 100
          }

          return currentSupplyPct <= valueNumber
        },
      },
      {
        title: intl.formatMessage({ id: "TOTAL_SUPPLY" }),
        render: (_, record: GetTokenSummaryResponse) => {
          const ticker = record.ticker || ""
          const { decimal } = record
          return (
            <Tooltip
              title={formatTokenAmount(ticker, record.maxSupply, decimal)}
            >
              {formatCash(record.maxSupply / Math.pow(10, decimal))}
            </Tooltip>
          )
        },
        sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
          a.maxSupply - b.maxSupply,
      },
      {
        title: intl.formatMessage({ id: "MINT_COUNT" }),
        render: (_, record: GetTokenSummaryResponse) => {
          const { mintTotal } = record

          return (
            <div className="d-flex align-items-center">
              {mintTotal?.toLocaleString()}
            </div>
          )
        },
        sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
          a.mintTotal - b.mintTotal,
      },
      {
        title: intl.formatMessage({ id: "HOLDER_COUNT" }),
        render: (_, record: GetTokenSummaryResponse) => {
          const { holderTotal } = record

          return (
            <div className="d-flex align-items-center">
              {holderTotal?.toLocaleString()}
            </div>
          )
        },
        sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
          a.holderTotal - b.holderTotal,
      },
    ]
  }, [intl, krc20PriceUnit])

  const onTableSearch = (val: string) => {
    if (val) {
      setTableData(
        tokens?.filter((row) =>
          row.ticker.toLowerCase().includes(val.toLowerCase())
        )
      )
    } else {
      setTableData(tokens)
    }
  }

  useEffect(() => {
    if (tokens && watchlistEnabled && bookmarkedTokens) {
      setTableData(
        tokens.filter((token) => bookmarkedTokens.includes(token.ticker))
      )
    } else {
      setTableData(tokens)
    }
  }, [tokens, watchlistEnabled, bookmarkedTokens])

  const DashboardCard = useMemo(() => {
    return (
      <div>
        <Table
          size="middle"
          loading={!tableData}
          columns={columns}
          dataSource={tableData}
          scroll={{ x: "max-content" }}
          pagination={{
            defaultPageSize: 20,
            current: Number(urlState.page),
            onChange: (page, _) => {
              setUrlState({ page })
            },
            showTotal: (total) =>
              intl.formatMessage(
                { id: "TOTAL_X_TOKENS" },
                {
                  total,
                }
              ),
          }}
        />
      </div>
    )
  }, [tableData, columns, intl, urlState.page, setUrlState])

  return (
    <div className="col-xl-12" id="top-addresses">
      <Card
        className="bg-body"
        title={intl.formatMessage({
          id: `TOKEN.HEADER.TOKEN_LIST.TITLE`,
        })}
        description={intl.formatMessage({
          id: `TOKEN.HEADER.TOKEN_LIST.DESCRIPTION`,
        })}
        toolbar={
          <div className="d-flex align-items-center">
            <Search
              placeholder={intl.formatMessage({
                id: "SEARCH_FOR_TOKEN_TICKER",
              })}
              allowClear
              onChange={(e) => onTableSearch(e.target.value)}
            />
            <span className="ms-4">
              <Krc20PriceUnitToggleButton className="fs-3" />
            </span>
            <span className="ms-4">
              <BookmarkTokenEnableButton />
            </span>
          </div>
        }
        body={DashboardCard}
      />
    </div>
  )
}

export { Krc20TokenList }
