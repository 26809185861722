import { useIntl } from "react-intl"
import { rowClass, TITLE_SUFFIX, UNIT_STRING } from "../../constants/constants"
import { HelmetWrapper } from "../../utils/HelmetWrappper"
import { formatCash, sompiToKaspa } from "../../utils/utils"
import { AdsPlacement } from "../../components/Ads/AdsPlacement"
import { useRequest } from "ahooks"
import {
  GetKrc20TokenListResponse,
  OpenAPI,
  TokenService,
} from "../../../client"
import { metricsCardClass } from "../dashboard/components/TopMetricsSection"
import { MetricCard } from "../dashboard/components/MetricCard"
import { Krc20TokenList } from "./Krc20TokenList"
import { useIndexedDB } from "../../hooks/useIndexDB"
import { generateNonce } from "../../utils/secrets"
import { TrendingCardsSection } from "./TrendingCardsSection"
import { Alert } from "antd"
import { Link } from "react-router-dom"

const TOKEN_KEY = "krc20Tokens"

const Krc20TokenDashboard = () => {
  const intl = useIntl()
  const {
    value: cachedTokens,
    setValue: setCachedTokens,
    loading: cacheLoading,
  } = useIndexedDB<GetKrc20TokenListResponse>(TOKEN_KEY)

  const { data: networkStatus, loading } = useRequest(
    TokenService.tokenControllerGetKrc20Status
  )

  const { data: freshTokens, loading: tokenLoading } = useRequest(
    async () => {
      const nonce = await generateNonce()
      OpenAPI.HEADERS = async () => ({
        "x-api-key": nonce,
      })

      return TokenService.tokenControllerGetKrc20TokenList()
    },
    {
      onSuccess: (data) => {
        if (data) {
          setCachedTokens(data)
        }
      },
    }
  )

  const tokens = freshTokens || cachedTokens

  const cards = [
    {
      svgIcon: "/media/icons/duotune/graphs/gra007.svg",
      title: networkStatus?.topTokensMarketCap
        ? `$${formatCash(Number(networkStatus?.topTokensMarketCap))}`
        : undefined,
      description: intl.formatMessage({
        id: "TOKEN.HEADER.METRICS.TOP20_MARKETCAP",
      }),
    },
    {
      svgIcon: "/media/icons/duotune/finance/fin008.svg",
      title: networkStatus?.feeTotal
        ? formatCash(sompiToKaspa(Number(networkStatus.feeTotal)))
        : undefined,
      description: intl.formatMessage({
        id: "TOKEN.HEADER.METRICS.TOTAL_FEES",
      }),
      titleSuffix: ` ${UNIT_STRING}`,
    },
    {
      svgIcon: "/media/icons/duotune/coding/cod004.svg",
      title: networkStatus?.tokenTotal
        ? Number(networkStatus?.tokenTotal)
        : undefined,
      description: intl.formatMessage({
        id: "TOKEN.HEADER.METRICS.TOTAL_TOKENS",
      }),
    },
    {
      svgIcon: "/media/icons/duotune/finance/fin010.svg",
      title: networkStatus?.globalTradingVolumeInUsd
        ? `$${formatCash(networkStatus?.globalTradingVolumeInUsd, 2)}`
        : undefined,
      description: intl.formatMessage({
        id: "TOKEN.HEADER.METRICS.GLOBAL_TRADING_VOLUME",
      }),
    },
  ]

  return (
    <>
      <HelmetWrapper
        title={`Kaspa KRC20 Tokens Dashboard ${TITLE_SUFFIX}`}
        description={"In-depth view of Kaspa's KRC20 Tokens"}
      />

      <Alert
        message={
          <Link
            to="https://x.com/cryptok777/status/1903694776232473075"
            className="text-body fw-semibold text-hover-primary"
            target="_blank"
          >
            {
              "Important: We’ve ended our partnership with Chainge/Knot.meme for the safety of our community. Read more >"
            }
          </Link>
        }
        type="warning"
        showIcon
        className="mb-4 mt-n8"
      />

      <div className="row">
        <TrendingCardsSection />
      </div>

      <div className="mb-2 mt-n2 justify-content-center">
        <AdsPlacement placementType="banner" platform="coinzilla" />
      </div>

      <div className={rowClass}>
        <div className="col-xl-12 mb-6">
          <Krc20TokenList tokens={tokens?.results} />
        </div>

        <div className={"row"}>
          {cards.map((row) => {
            return (
              <div className={metricsCardClass}>
                <MetricCard
                  className="bg-body card-xl-stretch mb-xl-8"
                  titleClass="text-dark"
                  descriptionClass="text-muted"
                  iconClass="svg-icon-primary"
                  {...row}
                />
              </div>
            )
          })}
        </div>

        <div className="mt-0 mt-xl-n2">
          <AdsPlacement placementType="banner" platform={"ads-server"} />
        </div>
      </div>
    </>
  )
}

export { Krc20TokenDashboard }
