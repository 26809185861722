/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import { useSize } from "ahooks"
import InnerHTML from "dangerously-set-html-content"
import { useMemo, useRef } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { trackEvent } from "../../utils/utils"
import { notification, Popconfirm } from "antd"
import { useIntl } from "react-intl"
import {
  coinzillaHtmlTemplateBanner,
  coinzillaHtmlTemplateBox,
  coinzillaHtmlTemplateMobileBanner,
} from "./coinzillaHtml"

declare global {
  interface Window {
    plausible: any
  }
}

const API_SERVER = process.env.REACT_APP_API_SERVER

type PlacementType = "banner" | "box"
export type PlatformType =
  | "coinzilla"
  | "cointraffic"
  | "adsense"
  | "ads-server"
  | "emission-schedule"
  | "none" // Do not show any ads

const CoinzillaAdsPlacement = ({
  placementType,
  width,
}: {
  placementType: PlacementType
  width?: number
}) => {
  const isMobile = width === undefined || width < 768

  if (placementType === "banner") {
    return (
      <InnerHTML
        html={
          isMobile
            ? coinzillaHtmlTemplateMobileBanner
            : coinzillaHtmlTemplateBanner
        }
      />
    )
  } else if (placementType === "box") {
    return <InnerHTML html={coinzillaHtmlTemplateBox} />
  } else {
    return null
  }
}

const CoinrafficAdsPlacement = ({
  placementType,
}: {
  placementType: PlacementType
}) => {
  if (placementType === "banner") {
    return (
      <>
        {/* desktop size */}
        <span id="ct_c11PkKAg2Er"></span>
        {/* mobile size */}
        <span id="ct_celcD29ypR1"></span>
      </>
    )
  } else if (placementType === "box") {
    return (
      <>
        {/* desktop size */}
        <span id="ct_cQS7he8nTrT"></span>
        {/* mobile size */}
        <span id="ct_cLmDvrhipJ8"></span>
      </>
    )
  } else {
    return null
  }
}

const AdSenseAdsPlacement = ({
  placementType,
}: {
  placementType: PlacementType
}) => {
  if (placementType === "banner") {
    return (
      <InnerHTML
        html={`
            <ins class="adsbygoogle adsense-banner"
              style="display:inline-block;"
              data-ad-client="ca-pub-1963873307882205"
              data-ad-slot="1535971851"
            </ins>
            <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
          `}
      />
    )
  } else {
    return null
  }
}

const AdsServerPlacement = ({
  placementType,
  width,
}: {
  placementType: PlacementType
  width?: number
}) => {
  const intl = useIntl()

  if (width === undefined) {
    return null
  }

  const couponCode = "KASFYI"
  const isMobile = width < 768
  const prefix = `${API_SERVER}/sponsor`

  const couponConfirmTitle = intl.formatMessage({
    id: "ADS_PLACEMENT.COUPON_CONFIRM_TITLE",
  })
  const couponConfirmDescription = intl.formatMessage(
    {
      id: "ADS_PLACEMENT.COUPON_CONFIRM_DESCRIPTION",
    },
    {
      code: (
        <a
          className="fw-bold text-primary"
          role="button"
          onClick={() => {
            navigator.clipboard.writeText(couponCode)
            notification.success({
              message: intl.formatMessage({
                id: "ADS_PLACEMENT.COUPON_CODE_COPIED",
              }),
            })
          }}
        >
          {couponCode}
        </a>
      ),
    }
  )

  const couponConfirmButton = intl.formatMessage({
    id: "ADS_PLACEMENT.COUPON_CONFIRM_BUTTON",
  })

  const confirmProps = {
    title: couponConfirmTitle,
    description: couponConfirmDescription,
    okText: couponConfirmButton,
    showCancel: false,
  }

  if (placementType === "banner") {
    const id = isMobile ? "banner-mobile" : "banner"
    return (
      <Popconfirm
        onConfirm={() => {
          navigator.clipboard.writeText(couponCode)
          trackEvent("click-ads", { size: placementType })
          window.open(`${prefix}/${id}/redirect`, "_blank")
        }}
        {...confirmProps}
      >
        <LazyLoadImage
          height={isMobile ? 100 : 90}
          width={isMobile ? 320 : 728}
          src={`${prefix}/${id}/view`}
          onLoad={() => trackEvent("view-ads", { size: placementType })}
          role="button"
        />
      </Popconfirm>
    )
  } else if (placementType === "box") {
    return (
      <Popconfirm
        onConfirm={() => {
          navigator.clipboard.writeText(couponCode)
          trackEvent("click-ads", { size: placementType })
          window.open(`${prefix}/box/redirect`, "_blank")
        }}
        {...confirmProps}
      >
        <LazyLoadImage
          width={300}
          height={250}
          src={`${prefix}/box/view`}
          onLoad={() => trackEvent("view-ads", { size: placementType })}
          role="button"
        />
      </Popconfirm>
    )
  } else {
    return null
  }
}

const EmissionScheduleAdsPlacement = ({
  width,
  placementType,
}: {
  width?: number
  placementType: PlacementType
}) => {
  if (width === undefined) {
    return null
  }
  const isMobile = width < 768
  const id = isMobile ? "banner-mobile" : "banner"

  return (
    <LazyLoadImage
      height={isMobile ? 100 : 90}
      width={isMobile ? 320 : 728}
      src={`/assets/sponsor/whalepool/${id}.png`}
      onLoad={() =>
        trackEvent("view-ads", {
          size: placementType,
        })
      }
      onClick={() => {
        trackEvent("click-ads", { size: placementType })
        window.open("https://www.whalepool.com/rf/wbeR0H", "_blank")
      }}
      role="button"
    />
  )
}

export const AdsPlacement = ({
  platform,
  placementType,
}: {
  placementType: PlacementType
  platform: PlatformType
}) => {
  const ref = useRef(null)
  const size = useSize(ref)

  const Ads = useMemo(() => {
    switch (platform) {
      case "coinzilla":
        return (
          <CoinzillaAdsPlacement
            placementType={placementType}
            width={size?.width}
          />
        )
      case "cointraffic":
        return <CoinrafficAdsPlacement placementType={placementType} />
      case "adsense":
        return <AdSenseAdsPlacement placementType={placementType} />
      case "ads-server":
        return (
          <AdsServerPlacement
            placementType={placementType}
            width={size?.width}
          />
        )
      case "emission-schedule":
        return (
          <EmissionScheduleAdsPlacement
            placementType={placementType}
            width={size?.width}
          />
        )
      default:
        return null
    }
  }, [placementType, platform, size?.width])

  return (
    <div className="d-flex justify-content-center" ref={ref}>
      {Ads}
    </div>
  )
}
