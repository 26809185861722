import { Popconfirm } from "antd"
import { useKasware } from "../../../hooks/useKasware"
import { useIntl } from "react-intl"
import { isSwapDisabled } from "../utils"

export const ConnectWalletButton = () => {
  const intl = useIntl()
  const { connectWallet, isKaswareInstalled } = useKasware()
  const swapDisabled = isSwapDisabled()

  if (swapDisabled) {
    return (
      <button
        type="button"
        className={`btn w-100 py-4 btn-secondary`}
        disabled={true}
      >
        {intl.formatMessage({ id: "SWAP_DISABLED" })}
      </button>
    )
  }

  return isKaswareInstalled ? (
    <button
      type="button"
      className="btn w-100 py-4 btn-secondary"
      onClick={connectWallet}
    >
      {intl.formatMessage({ id: "CONNECT_KASWARE" })}
    </button>
  ) : (
    <Popconfirm
      title={intl.formatMessage({ id: "INSTALL_KASWARE_WALLET" })}
      description={intl.formatMessage({
        id: "INSTALL_KASWARE_WALLET_DESCRIPTION",
      })}
      onConfirm={() => window.open("https://www.kasware.xyz/", "_blank")}
      okText="Yes"
      cancelText="No"
    >
      <button type="button" className="btn w-100 py-4 btn-secondary">
        {intl.formatMessage({ id: "CONNECT_KASWARE" })}
      </button>
    </Popconfirm>
  )
}
