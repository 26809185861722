/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressBriefResponse } from '../models/AddressBriefResponse';
import type { AddressOverviewResponse } from '../models/AddressOverviewResponse';
import type { DashboardMetricsResponse } from '../models/DashboardMetricsResponse';
import type { DistributionTrendResponse } from '../models/DistributionTrendResponse';
import type { GraphDataResponse } from '../models/GraphDataResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnalyticsService {

    /**
     * Return metrics of Kaspa
     * @returns DashboardMetricsResponse
     * @throws ApiError
     */
    public static analyticsControllerGetMetrics(): CancelablePromise<DashboardMetricsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/analytics/metrics',
        });
    }

    /**
     * Return overview of active addresses
     * @returns AddressOverviewResponse
     * @throws ApiError
     */
    public static analyticsControllerGetAddressesOverview(): CancelablePromise<AddressOverviewResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/analytics/addresses/overview',
        });
    }

    /**
     * Return a list of top 1000 addresses
     * @returns AddressBriefResponse
     * @throws ApiError
     */
    public static analyticsControllerGetRichList(): CancelablePromise<Array<AddressBriefResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/analytics/addresses/richList',
        });
    }

    /**
     * Return distribution trend chart data for all addresses in the Kaspa network
     * @param startDate Start date of the trend chart
     * @param endDate End date of the trend chart
     * @returns DistributionTrendResponse
     * @throws ApiError
     */
    public static analyticsControllerGetActiveAddressesTrend(
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<DistributionTrendResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/analytics/addresses/distributionTrend',
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
        });
    }

    /**
     * Return daily graph data of active addresses or transaction count
     * @param type Type of chart to return
     * @returns GraphDataResponse
     * @throws ApiError
     */
    public static analyticsControllerGetAnalyticsGraph(
        type: 'ActiveAddress' | 'TransactionCount',
    ): CancelablePromise<GraphDataResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/analytics/graphs/{type}',
            path: {
                'type': type,
            },
        });
    }

}
