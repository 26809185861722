import { useSwapService } from "../services/SwapService"

import { Table, Button, notification, Alert } from "antd"
import { SwapApiService } from "../../../../client"
import { Swap, SwapStatus } from "../services/models"
import { formatUnixTime } from "../../../utils/utils"
import { useKasware } from "../../../hooks/useKasware"
import { getSignableMessageForSwap } from "../utils"
import { hexlify, toUtf8Bytes } from "ethers"
import { Link } from "react-router-dom"
import { getTransactionRoute } from "../../../hooks/navigator"
import { formatTokenAmount } from "../../../utils/tokenUtils"
import { PasteIcon } from "../../../components/PasteIcon"
import BigNumber from "bignumber.js"
import { CountdownNumber } from "../../../components/partials/CountdownNumber"
import { SwapRate } from "./particles/SwapRate"
import { useIntl } from "react-intl"

export const SwapHistory = () => {
  const intl = useIntl()
  const { swaps, updateSwap } = useSwapService()
  const [api, contextHolder] = notification.useNotification()
  const { address, publicKey, sendKaspa, signKRC20Transaction, signMessage } =
    useKasware()

  const columns = [
    {
      title: intl.formatMessage({ id: "DATE" }),
      dataIndex: "createdAt",
      render: (createdAt: number) => {
        return (
          <span className="font-monospace">
            {formatUnixTime(createdAt / 1e3)}
          </span>
        )
      },
    },
    {
      title: intl.formatMessage({ id: "STATUS" }),
      dataIndex: "swapStatus",
      render: (status: string) => (
        <span className={`badge badge-${getStatusColor(status)}`}>
          {intl.formatMessage({ id: status.toUpperCase() })}
        </span>
      ),
    },
    {
      title: intl.formatMessage({ id: "SWAP_DIRECTION" }),
      render: (swap: Swap) => {
        const chainDecimal = swap.quoteResponse?.quote?.chainDecimal
        const toAssetDecimal = swap.toAsset?.decimals

        return (
          <div className="d-flex flex-column gap-2">
            <div className="d-flex gap-2">
              <span className="font-monospace">
                {`${formatTokenAmount(
                  swap.fromAsset?.symbol || "",
                  Number(swap.fromAmount),
                  0 // No decimal required, since we save whole amount from UI directly
                )}`}
              </span>
              <div>
                <i className="bi bi-arrow-right text-dark"></i>
              </div>
              <div>
                <span className="font-monospace">
                  {`${formatTokenAmount(
                    swap.toAsset?.symbol || "",
                    Number(swap.quoteResponse?.quote.expectedReceiveAmount),
                    chainDecimal!
                  )}`}
                </span>
              </div>
            </div>
            <div className="">
              {intl.formatMessage({ id: "ACTUAL_RECEIVED" })}:{" "}
              <span className="font-monospace">
                {formatTokenAmount(
                  swap.toAsset?.symbol || "",
                  Number(swap.swapOrder?.amountOut),
                  toAssetDecimal!
                )}
              </span>
            </div>
          </div>
        )
      },
    },
    {
      title: intl.formatMessage({ id: "RATE" }),
      render: (swap: Swap) => {
        return <SwapRate swap={swap} />
      },
    },
    {
      title: intl.formatMessage({ id: "PAYMENT_ID" }),
      dataIndex: "txnId",
      render: (txnId: string) =>
        txnId ? (
          <div className="d-flex align-items-center font-monospace">
            <Link
              to={getTransactionRoute(txnId)}
              className="text-truncate d-inline-block"
              style={{ maxWidth: "150px" }}
            >
              {txnId.slice(-6)}
            </Link>
            <PasteIcon value={txnId} showExplosion={false} />
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: intl.formatMessage({ id: "MAX_SLIPPAGE" }),
      dataIndex: "slippage",
      render: (slippage?: number) => (
        <span className="font-monospace">
          {slippage ? `${slippage}%` : "-"}
        </span>
      ),
    },
    {
      title: intl.formatMessage({ id: "ORDER_ID" }),
      dataIndex: "orderId",
      render: (orderId?: string) => (
        <span className="font-monospace">{orderId || "-"}</span>
      ),
    },
    {
      title: intl.formatMessage({ id: "ACTION" }),
      render: (swap: Swap) => {
        if (swap.swapStatus === SwapStatus.COMPLETED) return null

        if (swap.swapStatus === SwapStatus.SUBMITTED) {
          const PROCESSING_TIME_TARGET = 60 * 1e3 // 60 seconds
          const now = Date.now()
          const expectedTime =
            (swap?.submittedAt || now) + PROCESSING_TIME_TARGET
          const takingLongerThanExpected = now > expectedTime

          return (
            <div>
              {takingLongerThanExpected ? (
                <>
                  <Button loading>
                    {intl.formatMessage({ id: "ALMOST_THERE" })}
                  </Button>
                </>
              ) : (
                <Button loading>
                  {intl.formatMessage(
                    { id: "FINALIZING" },
                    {
                      time: <CountdownNumber to={expectedTime} />,
                    }
                  )}
                </Button>
              )}
            </div>
          )
        }

        const continuableSwapStatuses = [SwapStatus.PAID, SwapStatus.SIGNED]
        if (!continuableSwapStatuses.includes(swap.swapStatus)) {
          return null
        }

        return (
          <div className="d-flex gap-2">
            <Button
              type="primary"
              onClick={() => handleContinueSwap(swap)}
              disabled={swap.swapStatus === SwapStatus.NEW}
            >
              {intl.formatMessage({ id: "CONTINUE_SWAP" })}
            </Button>
          </div>
        )
      },
    },
  ]

  const getStatusColor = (status: string) => {
    const colors: Record<string, string> = {
      new: "light",
      quoted: "warning",
      paid: "info",
      signed: "primary",
      submitted: "secondary",
      completed: "success",
      refunded: "info",
      dropped: "danger",
    }
    return colors[status] || "light"
  }

  const handleContinueSwap = async (swap: Swap) => {
    try {
      if (!swap.quoteResponse) return
      const { signablePayload, quote } = swap.quoteResponse
      if (!sendKaspa || !signKRC20Transaction || !signMessage) {
        throw new Error(
          intl.formatMessage({ id: "WALLET_FUNCTIONS_NOT_AVAILABLE" })
        )
      }

      // For PAID status, need to sign and submit
      if (
        swap.swapStatus === SwapStatus.PAID ||
        swap.swapStatus === SwapStatus.SIGNED
      ) {
        const sourceCerts = {
          ...signablePayload.sourceCerts,
          fromAddr: address,
          certHash: swap.txnId!,
          fromPublicKey: publicKey,
        }
        const sourceCertsStr = JSON.stringify(sourceCerts)
        const sourceCertsHex = hexlify(toUtf8Bytes(sourceCertsStr)).substring(2)

        const signablePayloadWithSourceCerts = {
          ...signablePayload,
          sourceCerts: sourceCertsHex,
          toAddr: address,
          slippage: BigNumber(swap.slippage!)
            .multipliedBy(BigNumber(100))
            .toFixed(0),
        }

        if (swap.swapStatus === SwapStatus.PAID) {
          // Sign the payload
          const signableMessage = getSignableMessageForSwap({
            quote,
            signablePayload: signablePayloadWithSourceCerts,
            sourceCerts,
            toAsset: swap.toAsset!,
          })
          const signature = await signMessage(signableMessage)

          // Update to signed status
          swap.onSwapSigned(signature)
          updateSwap(swap)
        }

        // Submit the order
        const orderResponse = await SwapApiService.swapControllerSubmitOrder({
          address,
          publicKey,
          signature: swap.signature!,
          signedPayload: signablePayloadWithSourceCerts,
          certHash: swap.txnId!,
        })

        // Update to submitted status
        swap.onSwapSubmitted(orderResponse.orderId)
        updateSwap(swap)

        api.success({
          message: intl.formatMessage(
            { id: "SWAP_ORDER_SUBMITTED" },
            {
              toTicker: swap.toAsset?.symbol,
            }
          ),
          description: intl.formatMessage({
            id: "SWAP_ORDER_SUBMITTED_DESCRIPTION",
          }),
          placement: "top",
          showProgress: true,
          pauseOnHover: true,
        })
      }
    } catch (e: any) {
      api.error({
        message: intl.formatMessage({ id: "CONTINUE_SWAP_FAILED" }),
        description: intl.formatMessage(
          { id: "CONTINUE_SWAP_FAILED_DESCRIPTION" },
          { error: e.message }
        ),
        placement: "top",
      })
    }
  }

  return (
    <div>
      {contextHolder}
      <Alert
        description={
          <>
            {intl.formatMessage(
              {
                id: "SWAP_HISTORY_ALERT_DESCRIPTION",
              },
              {
                link: (
                  <Link to="https://t.me/knotdotmeme/2" target="_blank">
                    Telegram
                  </Link>
                ),
              }
            )}
          </>
        }
        type="warning"
        className="mb-3"
      />

      <Table
        dataSource={swaps.filter(
          (swap) =>
            swap.swapStatus !== SwapStatus.NEW &&
            swap.swapStatus !== SwapStatus.QUOTED
        )}
        columns={columns}
        rowKey="id"
        pagination={false}
        className="table-striped"
      />
    </div>
  )
}
